import * as React from "react"

export class Figure extends React.Component {
    constructor(props) {
        super(props);
        this.state = {zoom: 0};
    }

    render() {
        // is there a figure number?
        let fig_num = null;
        let fig_id = null;
        if (this.props.num !== undefined) {
            fig_num = <span className="figure_number">Figure {this.props.num}: </span>;
            fig_id = "figure_" + this.props.num;
        }

        // flatten out any <p> tags in the children, since markdown seems to put them everywhere
        let children = React.Children.map(this.props.children, child => {
            // If the item is a <p> tag then flatten it
            if (child.type === "p") {
                return React.Children.toArray(child.props.children);
            } else {
                return child;
            }        
        }).flat();

        // separate the <img> tag from the other children
        let img = null;
        children = children.map(child => {
            if (child.type === "img") {
                img = child;
                return null;
            } else {
                return child;
            }
        });

        // determine the size of the figure 
        let width = this.props.width || "var(--maxWidth-wrapper)";

        // weighted average zoom
        let alpha = 1 - this.state.zoom/100;
        let beta = 1 - alpha;
        let img_container_style = {width: "min(100%, calc(" + width + " * " + alpha + " + 100% * " + beta + "))"}
        let zoom_control = (
            <span className="figure_zoom_control">
                        Zoom: <input 
                            type="range" 
                            min="0" 
                            max="90" 
                            value={this.state.zoom}
                            onChange={e => this.setState({zoom: e.target.value})}
                            />
            </span>
        );

        return (
            <figure id={fig_id}>
                <div className="img_container" style={img_container_style}>
                    { img }
                </div>
                <figcaption>
                    {fig_num}
                    {children}
                    {zoom_control}
                </figcaption>
            </figure>
        )
    }
}

export class InteractiveFigure extends React.Component {
    constructor(props) {
        super(props);
        this.state = {zoom: 0};

        // parse the arrays of allowable interactive values and set defaults
        if (this.props.interactives) {
            this.interactives = JSON.parse(this.props.interactives);
            this.interactive_values = {};
            this.interactives.forEach(name => {
                this.interactive_values[name] = JSON.parse(this.props[name]);
                this.state[name] = 0;
            });
        }
    }

    render() {
        // is there a figure number?
        let fig_num = null;
        let fig_id = null;
        if (this.props.num !== undefined) {
            fig_num = <span className="figure_number">Figure {this.props.num}: </span>;
            fig_id = "figure_" + this.props.num;
        }

        // flatten out any <p> tags in the children, since markdown seems to put them everywhere
        let children = React.Children.map(this.props.children, child => {
            // If the item is a <p> tag then flatten it
            if (child.type === "p") {
                return React.Children.toArray(child.props.children);
            } else {
                return child;
            }        
        }).flat();

        // Set default variable labels
        let variable_labels = [];
        if (this.interactives) {
            for (let i = 0; i < this.interactives.length; i++) {
                variable_labels.push(<span key={i}>{this.interactives[i]}</span>);
            }
        }

        // Find the <img> tag that matches the current interactive values and set display:none
        // on all the others. If there is a <ol> tag, use it for the variable labels.
        let images = [];
        let found_match = false;
        children = children.map((child,i) => {
            if (typeof child === "string" && child.trim() === "") {
                return null;
            } else if (child.type === "img") {
                let is_match = true;
                if (!found_match && this.interactives) {
                    for (const name of this.interactives) {
                        const values = this.interactive_values[name];
                        let pattern = name + "=" + values[this.state[name]];
                        if (!child.props.src.includes(pattern)) {
                            is_match = false;
                            break;
                        }
                    }
                }

                if (is_match && !found_match) {
                    images.push(
                        <img key={i} src={child.props.src} />
                    )
                    found_match = true;
                } else {
                    images.push(
                        <img key={i} src={child.props.src} style={{display:'none'}} />
                    )
                }

                return null;
            } else if (child.type === "ol") {
                // iterate through <li> children to obtain variable labels
                let i = 0;
                React.Children.map(child.props.children, inner_child => {
                    if (inner_child.type === "li") {
                        variable_labels[i] = <span key={i}>{inner_child.props.children}</span>;
                        i += 1;
                    }
                })
                return null;
            } else {
                return child;
            }
        }).filter(child => child !== null);

        // determine the size of the figure 
        let width = this.props.width || "42rem";

        // weighted average zoom
        let alpha = 1 - this.state.zoom/100;
        let beta = 1 - alpha;
        let img_container_style = {width: "min(95vw, calc(" + width + " * " + alpha + " + 95vw * " + beta + "))"}

        // interactive controls
        let controls = [];
        if (this.interactives) {
            for (let i = 0; i < this.interactives.length; i++) {
                const name = this.interactives[i];
                const values = this.interactive_values[name];
                const label = variable_labels[i];
                controls.push(
                    <div key={name+"txt"} className="control_label">
                        {label} = {values[this.state[name]]}
                    </div>
                );
                controls.push(
                    <input type="range"
                        key={name+"ctrl"}
                        min={0}
                        max={values.length - 1}
                        value={this.state[name]}
                        onChange={e => this.setState({[name]: e.target.value})} 
                        />
                );
            }
        }

        return (
            <>
                <figure id={fig_id}>
                    <div className="img_container" style={img_container_style}>
                        { images }
                    </div>
                    <figcaption>
                        {fig_num}
                        {children}
                        <span className="figure_zoom_control">
                            Zoom: <input 
                                type="range" 
                                min="0" 
                                max="90" 
                                value={this.state.zoom}
                                onChange={e => this.setState({zoom: e.target.value})}

                                />
                        </span>
                        { controls ? <div className="figure_interactive_controls">{controls}</div> : null }
                    </figcaption>
                </figure>
            </>
        )
    }

}
