import * as React from "react"
import * as styles from './quiz.module.css'

export class Quiz extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            'status': 'unanswered',
            'mistakes': 0,
            'page': 0,
        };
        this.container_ref = React.createRef();
        this.page_ref = React.createRef();
        this.subheading_ref = React.createRef();
    }

    onChangeAnswer(key, value) {
        this.setState({[key]: value});
    }

    check(correct_answer, type, input_type, number_of_answers, num_pages) {
        let correct = true;
        if (type === "multichoice" || type === "fill_in_blanks_multichoice") {
            if (type === "fill_in_blanks_multichoice") {
                correct_answer = correct_answer[this.state.page]
            }
            if (input_type === "radio") {
                correct = (this.state.radio === correct_answer);
            } else {
                // check all answers 
                for (let i = 1; i <= number_of_answers; i++) {
                    let this_answer = correct_answer.includes(i);
                    let selected_answer = (this.state[i] === true);
                    if (this_answer !== selected_answer) {
                        correct = false;
                        break;
                    }
                }
            }
        } else if (type === "short_answer") {
            // check all answers 
            if (number_of_answers !== correct_answer.length) {
                console.error("Incorrect number of answers to quiz", this.props.title);
                correct = false;
            }

            for (let i = 0; i < correct_answer.length; i++) {
                // state answers are indexed from 1 but the array is indexed from 0
                if (correct_answer[i] != this.state[i+1]) {
                    correct = false;
                    break;
                }
            }
        } 

        if (correct) {
            let new_state = {'status': 'correct', 'mistakes': 0};
            if (type === "fill_in_blanks_multichoice") {
                let new_page = this.state.page + 1;
                if (new_page < num_pages) {
                    new_state["page"] = new_page;
                    if (new_page + 1 < num_pages) {
                        new_state["status"] = "unanswered";
                        // pulse animation correct
                        this.container_ref.current.animate(
                            [
                                {backgroundColor: "rgb(228, 241, 228)"},
                                {backgroundColor: "rgb(241, 241, 228)"},
                            ],
                            {
                                duration: 500,
                                interations: 1,
                            }
                        )
                        this.page_ref.current.animate(
                            [
                                {transform: "scale(0)"},
                                {transform: "scale(1)"},
                            ],
                            {
                                duration: 200,
                                interations: 1,
                            }
                        )
                        this.subheading_ref.current.animate(
                            [
                                {backgroundColor: "#F9F17F"},
                                {backgroundColor: "#F9F17F", offset: 0.8},
                                {backgroundColor: "none"},
                            ],
                            {
                                duration: 5000,
                                interations: 1,
                            }
                        )
                    }
                }
                new_state["radio"] = null;
            }
            this.setState(new_state);
        } else {
            this.setState({'status': 'incorrect', 'mistakes': this.state.mistakes+1});
        }
    }

    render() {
        if (!this.props.children) {
            return <p>Quiz parsing error: no children present</p>
        }

        let type = this.props.type || "multichoice";
        let input_type = this.props.input_type || "radio";

        // Read the answer
        let correct_answer = JSON.parse(this.props.answer);
        // if the answer is an array but should be a single value, then unpack it
        if (type === "multichoice" && input_type === "radio" && Array.isArray(correct_answer)) {
            correct_answer = correct_answer[0];
        }


        // Find the answers by flattening out <ol> tags, and also ensure that raw text is wrapped in <p> 
        let children = React.Children.map(this.props.children, (child,i) => {
            if (child.type === "ol") {
                return React.Children.toArray(child.props.children);
            } else if (typeof child === "string" && child.trim() !== "") {
                return <p key={i}>{child}</p>;
            } else {
                return child;
            }
        }).flat();

        // Assign numbers to each of the answers 
        let answer_number = 0;
        children = children.map(child => {
            if (child.type === "li") {
                answer_number += 1;
                if (type === "fill_in_blanks_multichoice" && this.state.status === "correct") {
                    return null;
                } else {
                    return (
                        <Answer 
                            key={answer_number}
                            title={this.props.title}
                            type={type} 
                            input_type={input_type}
                            answer_number={answer_number} 
                            current_answer={this.state}
                            onChangeAnswer={(key,value) => this.onChangeAnswer(key,value)}>
                                { child.props.children }
                        </Answer>
                    );
                }
            } else {
                return child;
            }
        });

        // For fill in the blanks questions, find the appropriate image child 
        let num_blanks = null;
        let subheading = null;
        let progress_indicator = null;
        if (type === "fill_in_blanks_multichoice") {
            // Find the <div className="blanks"> child
            let images = [];
            children = children.map(child => {
                // assuming there is only one such div tag
                if (child?.type === "div" && child.props.className === "blanks") {
                    // flatten out <p> tags 
                    let blanks_children = React.Children.map(child.props.children, c => {
                        if (c.type === "p" && c.props.children) {
                            return React.Children.toArray(c.props.children);
                        }
                    }).flat();

                    blanks_children.forEach(c => {
                        if (c.type === "img") {
                            images.push(c);
                        }
                    })
                    
                    if (images.length > this.state.page) {
                        num_blanks = images.length;
                        subheading = <h5 ref={this.subheading_ref}>Question {this.state.page+1} of {num_blanks}</h5>;

                        return (
                            <figure ref={this.page_ref}>
                                {images.map((img, i) => {
                                    if (i === this.state.page) {
                                        return <img key={i} src={img.props.src} />;
                                    } else {
                                        return <img key={i} src={img.props.src} style={{display:"none"}} />;
                                    }
                                })}
                            </figure>
                        )
                    } else {
                        return "Warning: no images were found, put <div class=\"blanks\"> with blank lines around it.";
                    }
                } else {
                    return child;
                }
            })
            if (num_blanks > 0) {
                progress_indicator = "Questions: ";
                for (let i = 0; i < num_blanks; i++) {
                    if (i < this.state.page) {
                        progress_indicator += "🟩 ";
                    } else if (i == this.state.page) {
                        if (this.state.status === "correct") {
                            progress_indicator += "🟩 ";
                        } else {
                            progress_indicator += "🟨 ";
                        }
                    } else {
                        progress_indicator += "⬜ ";
                    }
                }
            }
        }

        let mistakes_msg = "";
        if (this.state.mistakes > 0) {
            if (this.state.mistakes <= 5) {
                for (let i = 0; i < this.state.mistakes; i++) {
                    mistakes_msg = mistakes_msg + "😞";
                }
            } else if (this.state.mistakes <= 10) {
                for (let i = 5; i < this.state.mistakes; i++) {
                    mistakes_msg = mistakes_msg + "😒";
                }
            } else if (this.state.mistakes <= 15) {
                for (let i = 10; i < this.state.mistakes; i++) {
                    mistakes_msg = mistakes_msg + "😣";
                }
            } else if (this.state.mistakes <= 20) {
                for (let i = 15; i < this.state.mistakes; i++) {
                    mistakes_msg = mistakes_msg + "😭";
                }
            } else {
                mistakes_msg = mistakes_msg + "💀 x " + this.state.mistakes;
            }
        }
        
        let container_classname = styles.quiz_container + " " + styles[this.state.status];
        let submit_button;
        if (type === "fill_in_blanks_multichoice" && this.state.status === "correct") {
            submit_button = null;
        } else {
            submit_button = (
                <input 
                    className={styles.button}
                    type="submit"
                    value="Check"/>
            );
        }

        // render the answer 
        let rendered_answer = null;
        if (type === "multichoice") {
            if (Array.isArray(correct_answer)) {
                rendered_answer = correct_answer.map(i => {
                    return "(" + String.fromCharCode(96 + i) + ")";
                }).join(", ");
            } else {
                rendered_answer = "(" + String.fromCharCode(96 + correct_answer) + ")";
            }
        } else if (type === "fill_in_blanks_multichoice") {
            rendered_answer = correct_answer.map((page_ans, i) => {
                if (Array.isArray(page_ans)) {
                    return "Page " + (i+1) + ": " + page_ans.map(i => {
                        return "(" + String.fromCharCode(96 + i) + ")";
                    }).join(", ");
                } else {
                    return "Page " + (i+1) + ": " + String.fromCharCode(96 + page_ans) + "";
                }
            }).join("; ");
        } else if (type === "short_answer") {
            rendered_answer = correct_answer.toString();
        }

        return ( 
            <div ref={this.container_ref} className={container_classname}>
                    <form onSubmit={e => {
                        e.preventDefault();
                        this.check(correct_answer, type, input_type, answer_number, num_blanks)
                    }}>
                    <h4>{this.props.title || "Self-check quiz"}</h4>
                    { subheading }
                    { children }
                    { submit_button }
                    <button 
                        className={styles.button}
                        onClick={e => {e.preventDefault(); this.setState({"radio": null, "page": 0, "status": "unanswered"})}} >
                        Start again
                    </button>
                    </form>
                    { progress_indicator ? <p>{progress_indicator}</p> : null }
                    {
                        (this.state.status === "correct") 
                        ?
                        <div className={styles.feedback}>
                            <p>✔️ Correct!</p>
                            { this.props.feedback_when_correct 
                                ? <p>{this.props.feedback_when_correct}</p>
                                : null
                            }
                        </div> 
                        :
                        null
                    }
                    {
                        (this.state.status === "incorrect") 
                        ?
                        <div className={styles.feedback}>
                            <p>{ mistakes_msg } Sorry, please try again!</p>
                            { this.props.feedback_when_incorrect 
                                ? <p>{this.props.feedback_when_incorrect}</p>
                                : null
                            }
                        </div> 
                        :
                        null
                    }
                <div className="answer_section">
                    Answer: {rendered_answer}
                </div>
            </div>
        )
    }
}

class Answer extends React.Component {
    render_multichoice() {
        let answer_letter_only = String.fromCharCode(96 + this.props.answer_number);
        let answer_letter = "(" + answer_letter_only + ")";
        let input_control_id = "answer_" + this.props.title + "_" + answer_letter_only;

        // determine if the radio button or check box is ticked
        let checked;
        if (this.props.input_type === "radio") {
            checked = (this.props.current_answer.radio === this.props.answer_number);
        } else {
            checked = (this.props.current_answer[this.props.answer_number] === true);
        }

        return (
            <label className={styles.answer + " content " + styles.multichoice} htmlFor={input_control_id}>
                <div className={styles.answer_letter}>
                    <input type={this.props.input_type} 
                        checked={checked} 
                        id={input_control_id}
                        value={answer_letter_only}
                        onChange={e => {
                            if (this.props.input_type === "radio") {
                                this.props.onChangeAnswer("radio", this.props.answer_number);
                            } else {
                                this.props.onChangeAnswer(this.props.answer_number, !checked);
                            }
                        }} />
                    {answer_letter}
                </div>
                <div className={styles.answer_text}>
                    {this.props.children}
                </div>
            </label>
        )
    }

    render_short_answer() {
        let blank_id = 0;

        // place the input box where the <span>blank</span> elements are in the children
        let children = React.Children.map(this.props.children, child => {
            if (child.type === "span" && child.props.children.length > 0 && child.props.children[0] === "blank") {
                blank_id += 1;
                if (blank_id >= 2) {
                    return <span>Cannot have two blanks within the same answer yet</span>;
                } 
                return <input className={styles.short_answer}
                    onChange={e => this.props.onChangeAnswer(this.props.answer_number, e.target.value)}
                    />;
            } else {
                return child;
            }
        });

        return (
            <div className={styles.answer + " content " + styles.short_answer}>
                {children}
            </div>
        )
    }

    render() {
        if (this.props.type === "multichoice" || this.props.type === "fill_in_blanks_multichoice") {
            return this.render_multichoice();
        } else if (this.props.type === "short_answer") {
            return this.render_short_answer();
        } else {
            return <div>Unknown quiz type {this.props.type}</div>;
        }
    }
}
