import * as React from "react"

export class OptionalContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'shown': false,
        };
    }

    render() {
        let label = this.props.label || "content"

        // make sure the children are <p> wrapped
        let children = React.Children.map(this.props.children, child => {
            if (typeof child === "string" && child.trim() !== "") {
                return <p>{child}</p>;
            } else {
                return child;
            }
        });

        let button;
        let display_class;

        if (this.state.shown) {
            display_class = "optional-content visible";
            button = (
                <button onClick={e => this.setState({'shown': false})}>
                    Hide {label}
                </button>
            );
        } else {
            display_class = "optional-content hidden";
            button = (
                <button onClick={e => this.setState({'shown': true})}>
                    Show {label}
                </button>
            );
        }

        return (
            <>
                <p>{button}</p>
                <div className={display_class}>
                    {children}
                </div>
            </>
        )
    }
}
