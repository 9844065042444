import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function Byline (props) {
  let picture = <StaticImage
    className="bio-avatar"
    layout="fixed"
    formats={["auto", "webp", "avif"]}
    src="../images/profile-pic.png"
    width={50}
    height={50}
    quality={95}
    alt="Profile picture" />
  
  return (
    <div className="bio">
      { picture }
      <div>
        <div className="bio1">Written by <a href="https://jcu.me/bronson.philippa"><strong>Dr. Bronson Philippa</strong></a></div>
        <div className="bio2">College of Science and Engineering, James Cook University</div>
        {props.date ? <div className="date">Last updated: {props.date}</div> : null}
      </div>
    </div>
  )
}
