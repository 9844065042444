import * as React from "react"
import { Link, graphql } from "gatsby"
import rehypeReact from "rehype-react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Byline from "../components/byline"
import {Quiz} from "../components/quiz"
import {Figure, InteractiveFigure} from "../components/figure"
import {TutorialQuestion} from "../components/tutorial"
import {OptionalContent} from "../components/optionalcontent"
import {LinkCard} from "../components/linkcard"

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { 
    // rehype converts all tags to lower case
    quiz: Quiz,
    figure: Figure,
    interactivefigure: InteractiveFigure,
    tutorialquestion: TutorialQuestion,
    optionalcontent: OptionalContent,
    linkcard: LinkCard,
  }
}).Compiler

function Toc({pages, links}) {
  return (
    <>
      {pages?.map(({node}) => {
        const title = node.frontmatter.title || node.fields.slug

        return (
          <div key={node.fields.slug} className="linkcard">
            <Link to={node.fields.slug} itemProp="url">
            <h2>{title}</h2>
            <p
              dangerouslySetInnerHTML={{
                __html: node.frontmatter.description || node.frontmatter.intro,
              }}
            />
            </Link>
          </div>
        )
      })}

      {links?.map(({href, title, subtitle, description}, i) => (
        <LinkCard key={i} href={href} title={title} subtitle={subtitle}>
          {description}
        </LinkCard>
      ))}
    </>
  )
}

export default function Template({ data }) {
  const page = data.markdownRemark
  const pages = data.toc.edges;
  let subjectCode = (
    data.ancestor1?.frontmatter.subjectCode ||
    data.ancestor2?.frontmatter.subjectCode ||
    data.ancestor3?.frontmatter.subjectCode ||
    data.ancestor4?.frontmatter.subjectCode ||
    null
  );

  let breadcrumbs = [];
  if (page.fields.slug !== "/") {
    for (let ancestor_id = 4; ancestor_id > 0; ancestor_id--) {
      let ancestor = data["ancestor" + ancestor_id];
      if (ancestor) {
        breadcrumbs.push(
          <span key={ancestor_id+"_sep"} className="separator"> / </span>
        );
        breadcrumbs.push(
          <Link key={ancestor_id+"_link"} className="header-link-home" to={ancestor.fields.slug}>
            {ancestor.frontmatter.shortTitle || ancestor.frontmatter.title}
          </Link>
        );
      }
    }
  }
  breadcrumbs.push(
    <span key={"0_sep"} className="separator"> / </span>
  );
  breadcrumbs.push(
    <Link key={"0_link"} className="header-link-home" to={page.fields.slug}>
      {page.frontmatter.shortTitle || page.frontmatter.title}
    </Link>
  );

  let backlink = null;
  if (data.ancestor1) {
    backlink = (
      <Link to={data.ancestor1.fields.slug} rel="prev">
        ← Back to {data.ancestor1.frontmatter.shortTitle || data.ancestor1.frontmatter.title}
      </Link>
    ) 
  }

  let headline = (
    <div className="h1_container">
      <h1 itemProp="headline">
        {subjectCode ? <div className="subheading">{subjectCode}</div> : null}
        {page.frontmatter.fullTitle ? <div className="subheading">{page.frontmatter.title}</div> : null}
        {page.frontmatter.fullTitle ? page.frontmatter.fullTitle : page.frontmatter.title}
      </h1>
    </div>
  );

  return (
    <Layout>
      <Seo
        title={page.frontmatter.title}
        description={page.frontmatter.description || page.excerpt}
      />

      <nav className="breadcrumbs">{breadcrumbs}</nav>

      <article
        className={page.frontmatter.articleClass}
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          {headline}
          <Byline date={page.frontmatter.date}/>
          <p dangerouslySetInnerHTML={{__html: page.frontmatter.intro}}></p>
        </header>
        <section>
          {
            renderAst(page.htmlAst)
          }
        </section>

        <Toc pages={pages} links={page.frontmatter.links} />
      </article>

      <footer className="navlinks">
        { backlink }
        {" "}
        <a href="#top">↑ To top of page</a>
      </footer>
    </Layout>
  )
}

export const pageQuery = graphql`
query GetPageBySlug($slug: String!, $ancestor1: String, $ancestor2: String, $ancestor3: String, $ancestor4: String) {
  site {
    siteMetadata {
      title
    }
  }
  markdownRemark(fields: {slug: {eq: $slug}}) {
    htmlAst
    frontmatter {
      title
      fullTitle
      shortTitle
      date(formatString: "DD MMMM YYYY")
      description
      intro
      articleClass
      links {
        href
        title
        subtitle
        description
      }
    }
    fields {
      slug
    }
  }
  ancestor1: markdownRemark(fields: {slug: {eq: $ancestor1}}) {
    frontmatter {
      title
      shortTitle
      subjectCode
    }
    fields {
      slug
    }
  }
  ancestor2: markdownRemark(fields: {slug: {eq: $ancestor2}}) {
    frontmatter {
      title
      shortTitle
      subjectCode
    }
    fields {
      slug
    }
  }
  ancestor3: markdownRemark(fields: {slug: {eq: $ancestor3}}) {
    frontmatter {
      title
      shortTitle
      subjectCode
    }
    fields {
      slug
    }
  }
  ancestor4: markdownRemark(fields: {slug: {eq: $ancestor4}}) {
    frontmatter {
      title
      shortTitle
      subjectCode
    }
    fields {
      slug
    }
  }
  toc: allMarkdownRemark(
    filter: {fields: {ancestor1: {eq: $slug}}}
    sort: {fields: [frontmatter___sortKey fields___sortKey frontmatter___title]}
  ) {
    edges {
      node {
        frontmatter {
          title
          description
          intro
        }
        fields {
          slug
        }
      }
    }
  }
}
`
