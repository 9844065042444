import * as React from "react"
import * as styles from './tutorial.module.css'

export class TutorialQuestion extends React.Component {
    render() {
        // Find the answers by searching for <div class="answer"> tags
        let children = React.Children.map(this.props.children, child => {
            if (child.type === "div" && child.props.className === "answer") {
                return (
                    <TutorialAnswer>{child.props.children}</TutorialAnswer>
                )
            } else if (typeof child === "string" && child.trim() !== "") {
                return <p>{child}</p>;
            } else {
                return child;
            }
        });

        return (
            <div className={styles.question}>
                {children}
            </div>
        )
    }
}

class TutorialAnswer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            'shown': false,
        };
    }

    render() {
        // make sure the answers are <p> wrapped
        let children = React.Children.map(this.props.children, child => {
            if (typeof child === "string" && child.trim() !== "") {
                return <p>{child}</p>;
            } else {
                return child;
            }
        });

        let button;
        let display_class;

        if (this.state.shown) {
            display_class = styles.answer + " visible";
            button = (
                <button onClick={e => this.setState({'shown': false})}>
                    Hide answer
                </button>
            );
        } else {
            display_class = styles.answer + " hidden";
            button = (
                <button onClick={e => this.setState({'shown': true})}>
                    Show answer
                </button>
            );
        }

        return (
            <>
                <p>{button}</p>
                <div className={display_class}>
                    {children}
                </div>
            </>
        )
    }

}
