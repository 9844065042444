import * as React from "react"

export function LinkCard(props) {
    return (
        <div className="linkcard">
            <a href={props.href}>
                <h2>{props.title}</h2>
                {props.subtitle ? <h3>{props.subtitle}</h3> : null}
                <p>{props.children}</p>
            </a>
        </div>
    );
}
