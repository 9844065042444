// extracted by mini-css-extract-plugin
export var quiz_container = "quiz-module--quiz_container--634c9";
export var unanswered = "quiz-module--unanswered--3d316";
export var correct = "quiz-module--correct--b0873";
export var feedback = "quiz-module--feedback--2600d";
export var verticalExpand = "quiz-module--vertical-expand--22f02";
export var incorrect = "quiz-module--incorrect--80c14";
export var answer = "quiz-module--answer--af3dd";
export var short_answer = "quiz-module--short_answer--b3399";
export var answer_letter = "quiz-module--answer_letter--6cbf0";
export var answer_text = "quiz-module--answer_text--2617b";
export var multichoice = "quiz-module--multichoice--5e520";
export var button = "quiz-module--button--d111e";
export var answer_for_print = "quiz-module--answer_for_print--b45f4";